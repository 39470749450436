import React from "react";
import Home from "./Components/Home";

import { SnackbarProvider, useSnackbar } from 'notistack';


function App() {
  return (
      <SnackbarProvider maxSnack={3}>
    <div>
     <Home/>
    </div>
      </SnackbarProvider>
  );
}

export default App;
