import React, {useEffect, useState} from "react";


import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { jsPDF } from "jspdf";
import {KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";

import axios from 'axios';

import {useSnackbar } from 'notistack';
import LinearProgress from "@material-ui/core/LinearProgress";








export default function Home (props)    {

    const { enqueueSnackbar } = useSnackbar();


    const [reportReady,setReportReady] = useState(false);
    const [report,setReport] = useState({});
    const [loading,setLoading] = useState(false);
    const [dob,setDob] = React.useState(null);

    const urlParams = window.location.search;



    const downloadPDF = ()=>{

        const doc = new jsPDF(
            {
                orientation: "portrait",
                // format: [600, 1000],
            }
        );

        doc.html(document.body, {
            html2canvas: {
                scale: .3,
            },
            callback: function (doc) {
                doc.save("Report.pdf");
            },
            x: 1,
            y: 1,
        }).then(()=>{
            console.log("done")
        });

    };


    const getResults = (event)=>{

        event.preventDefault();

        //check if dob is incomplete
        if(isNaN(dob)){
            enqueueSnackbar("Invalid date of birth",{
                variant: 'error',
            });
            return;
        }



        setLoading(true);




        //check if there's r

        const reportNumber = new URLSearchParams(urlParams).get("r");
        if(reportNumber)
        {
            if(dob)
            {
                axios.post("https://us-central1-hometestbox-aad36.cloudfunctions.net/getResults",
                    {
                        "report":new URLSearchParams(urlParams).get("r"),
                        "dob":new Date(dob).toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit',timeZone:"UTC"}),
                    },
                ).then((res)=>{
                    setReport(res.data);
                    setReportReady(true);
                    console.log(res.data)
                }).catch((err)=>{
                    console.log(err)
                    enqueueSnackbar("Could not verify birthday",{
                        variant: 'error',
                    })
                    setLoading(false);
                })
            }
            else
            {
                enqueueSnackbar("Invalid date of birth",{
                    variant: 'error',
                });

                setLoading(false);
            }

        }
        else
        {
            enqueueSnackbar("Invalid Link",{
                variant: 'error',
            });

            setLoading(false);

        }

    };






    if(urlParams)
    return(
       <>

           {!reportReady?(<>
               <form onSubmit={getResults}>
            <MuiPickersUtilsProvider utils={DayjsUtils}>

                <LinearProgress style={{display:loading?"":"none"}} />

                <Grid  container  spacing={2}  style={{margin:"1rem",maxWidth:"95%"}}>



                   <Grid item xs={12}>
                       <img src="logo.png" alt="Logo" style={{width:"10rem",height:"auto",marginLeft:"-20px"}}/>

                   </Grid>


                   <Grid item xs={12} style={{textAlign:"center"}}>
                       <Typography gutterBottom variant={"p"} style={{fontSize:"1rem"}}>
                           Please enter your date of birth below to view your results.
                       </Typography>
                   </Grid>



                       <Grid item xs={8} style={{textAlign:"center", marginLeft:"auto", marginRight:"auto"}} >

                           <KeyboardDatePicker
                               style={{width:"90%"}}
                               inputVariant="outlined"
                               disableFuture
                               autoOk
                               clearable
                               value={dob}
                               onChange={date=>setDob(date)}
                               label="Date of birth"
                               maxDateMessage={"Please select a valid date for the Date of Birth."}
                               minDateMessage={"Please select a valid date for the Date of Birth."}
                               placeholder="MM/DD/YYYY"
                               format="MM/DD/YYYY"/>

                       </Grid>


                   <Grid item xs={12} style={{textAlign:"center", marginLeft:"auto", marginRight:"auto"}}>

                   <Button  type={"submit"} variant={"outlined"} disabled={loading}>
                       Get Results
                   </Button>

                   </Grid>


               </Grid>

               </MuiPickersUtilsProvider>
               </form>
               </>):

           <Grid container spacing={3} style={{margin:"1rem",maxWidth:"95%"}} >

               <img src="logo.png" alt="Logo" style={{maxWidth:"10rem",maxHeight:"2.5rem",width:"auto",height:"auto",marginLeft:"-20px"}}/>

               <Grid item xs={12}>
                  <Button variant={"outlined"} style={{textAlign:"right",float:"right"}} onClick={downloadPDF}>
                      Print PDF
                  </Button>
               </Grid>


               <Grid item xs={12}>
                   <Typography  variant={"h1"} style={{fontSize:"2rem"}}>
                       Patient Information
                   </Typography>
                   <Divider/>
               </Grid>


               <Grid item xs={12}>
                   <Typography gutterBottom variant={"p"} style={{fontSize:"1rem"}}>
                       <b>Name:</b> {report.patient.fname + " " + report.patient.lname}<br/>
                       <b>Date of Birth:</b> {report.patient.dob}<br/>
                       <b>Gender:</b> {report.patient.gender}<br/>
                       <b>Phone:</b> {report.patient.phone}<br/>
                   </Typography>
               </Grid>


               <Grid item xs={12}>
                   <Typography  variant={"h1"} style={{fontSize:"2rem"}}>
                       Specimen Information
                   </Typography>
                   <Divider/>
               </Grid>

               <Grid item xs={12}>
                   <Typography gutterBottom variant={"p"} style={{fontSize:"1rem"}}>
                       <b>Test:</b> {report.sample.test}<br/>
                       <b>Barcode:</b> {report.sample.barcode}<br/>
                       <b>Date collected:</b> {new Date(Date.parse(report.sample.collectionTime)).toLocaleString() }<br/>
                       <b>Date reported:</b> {new Date(report.sample.reportTime._seconds *1000).toLocaleString() }<br/>
                       <b>Lab:</b> {report.result.lab}<br/>

                   </Typography>
               </Grid>




               <Grid item xs={12}>
                   <Typography  variant={"h1"} style={{fontSize:"2rem"}}>
                       Result: {report.result.result}
                   </Typography>
                   <Divider/>
               </Grid>


               {report.result.result.toLowerCase()==="negative" &&
               <Grid item xs={12}>
                   <Typography gutterBottom variant={"p"} style={{fontSize:"1rem"}}>
                       A Not Detected (negative) test result for this test means that SARS- CoV-2 RNA was not present in the specimen above the limit of detection. A negative result does not rule out the possibility of COVID-I9 and should not be used as the sole basis for treatment or patient management decisions. If COVID-19 is still suspected, based on exposure history together with other clinical findings, re-testing should be considered in consultation with public health authorities. Laboratory test results should always be considered in the context of clinical observations and epidemiological data in making a final diagnosis and patient management decisions.
                   </Typography
                   >
               </Grid>}


               {report.result.result.toLowerCase()==="positive" &&
               <Grid item xs={12}>
                   <Typography gutterBottom variant={"p"} style={{fontSize:"1rem"}}>
                       Positive results are indicative of the presence of SARS-CoV-2 clinical correlation with patient history and other diagnostic information is necessary to determine patient infection status. Positive results do not rule out bacterial infection or co-infection with other viruses. Positive and negative predictive values of testing are highly dependent on prevalence. False positive test results are more likely when prevalence is moderate to low.
                   </Typography>
               </Grid>}


               <Grid item xs={12}>
                   <Typography  variant={"h1"} style={{fontSize:"2rem"}}>
                       Test Site
                   </Typography>
                   <Divider/>
               </Grid>


               <Grid item xs={12}>
                   <Typography gutterBottom variant={"p"} style={{fontSize:"1rem"}}>
                       <b>Facility:</b> {report.sample.facility}<br/>
                       <b>Doctor:</b> {report.result.doctor}<br/>
                   </Typography>
               </Grid>




           </Grid>}
       </>
    )
    else
        return (
            <>
                <Grid container>
                    <Grid item style={{textAlign:"center",width:"100%",marginTop:"50%"}}>
                        <Typography>
                            Invalid Link
                        </Typography>
                        <img src="logo.png" alt="Logo" style={{width:"200px",marginLeft:"-20px"}}/>

                    </Grid>
                </Grid>
           </>
        )
}
